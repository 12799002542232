import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { UserSettingsService } from '@shared/services/user-settings.service';

/** механизм проверки, наличия ролей
 * ожидает параметром роль, для которой доступен раут
 * роли хранятся в UserRolesService
 * */
@Injectable()
export class BrokersRolesGuard implements CanActivate {
  constructor(private userSettingsService: UserSettingsService) {}

  public canActivate(next: ActivatedRouteSnapshot): boolean {
    // роль участник, передаем в data в месте использования BrokersRolesGuard
    const role = next.data['rolesParticipant'];
    return this.userSettingsService.haveCurrentRole(role);
  }
}
