import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrokersAuthGuard } from '../auth/broker-auth-page/guards/brokers-auth.guard';

import { DealerClientAuthGuard } from '../auth/user-filter-page/guards/dealer-client-auth-guard.service';

import { SpinnerComponent } from './components/spinner/spinner.component';
import { DisableControlDirective } from './directives/disable-control-directive.directive';
import { BrokerTokenInterceptor } from './interceptors/broker-token.interceptor';
import { LoadingInterceptor } from './interceptors/loading.interceptor';
import { LoaderService } from './services/loader.service';
import { MatDialogModule } from '@angular/material/dialog';
import { BrokersRolesGuard } from '@auth/broker-auth-page/guards/brokers-roles.guard';

@NgModule({
  declarations: [SpinnerComponent, DisableControlDirective],
  imports: [CommonModule, MatDialogModule],
  exports: [SpinnerComponent],
  providers: [
    BrokersAuthGuard,
    BrokersRolesGuard,
    DealerClientAuthGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BrokerTokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingInterceptor,
      multi: true,
    },
    LoaderService,
  ],
})
export class DealerSharedModule {}
