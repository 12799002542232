import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { iif, Observable, of, shareReplay, take, timer } from 'rxjs';
import { DialogEvent } from '@dealer/pages/settings-cabinet/components/employee-cervice-centers-page/components/set-pin-dealer-center-dialog/set-pin-dealer-center-dialog.component';
import { map, takeUntil } from 'rxjs/operators';
import { DestroyService } from '@core/services/destroy.service';

export interface ConfirmationDialogData {
  messageOne: string;
  messageTwo: string;
  confirmButton: boolean;
  delayTime: number;
  buttonTitle: string;
  rejectButton?: boolean;
  rejectButtonTitle?: string;
  withoutTimer: boolean;
}

@Component({
  selector: 'tes-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmationDialogComponent {
  delay$: Observable<number> = iif(
    () => !this.data.withoutTimer,
    timer(0, 1000).pipe(
      map((sec) => 10 - sec),
      take(11),
      shareReplay(),
    ),
    of(0),
  );
  title$ = this.delay$.pipe(map((sec) => (sec > 0 ? sec : 'Понятно')));
  disabled$ = this.delay$.pipe(map((sec) => sec > 0));

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ConfirmationDialogData,
    private dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    private dialog: MatDialog,
    private destroy$: DestroyService,
  ) {}

  submitDialog(): void {
    this.dialogRef.close({ event: DialogEvent.SUBMIT });
    this.dialogRef
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.dialog.closeAll());
  }

  closeDialog(): void {
    this.dialogRef.close({ event: DialogEvent.CLOSE });
  }
}
