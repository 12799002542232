export const enum routingPathEnum {
  BrokerAuthentication = 'auth-broker',
  ClientFilterAuth = 'auth-filter-client',
  ClientAuthentication = 'auth-client',
  BrokerDashboard = 'dashboard',

  MainPage = 'main-page',

  Settings = 'settings',
  PersonalAccount = 'personal-account',
  EmployeeRoles = 'employee-roles',
  GroupDealerSettings = 'group-dealer-settings',
  PartnerPrioritySettings = 'partner-priority-settings',
  SalesPlans = 'sales-plans',
  KnowledgBaseAdministrator = 'knowledge-base-administrator',
  SettingProductsVisibility = 'setting-products-visibility',
  ClientBaseAdministrator = 'client-base-administrator',

  LoanCalculationPage = 'loan-calculation',
  ProcessingPage = 'processing',
  DocumentsAndPaymentsPage = 'documents-payments',

  ProductList = 'product-list',
  CarInfo = 'car-info',
  CarOwner = 'owner',
  Drivers = 'drivers',
  LoanParams = 'loan-params',
  InsuranceParams = 'insurances-params',
  ProductsCalculation = 'product-calculation',

  ClientInfo = 'client-info',
  ClientJobData = 'client-job',
  ClientSummary = 'client-summary',
  ClientApproval = 'client-approval',

  CustomerAccount = 'customer-account',
  PersonalData = 'personal-data',
  MyPolicies = 'policies',
  ChosenData = 'chosen-data',
  DocumentsAndPayments = 'documents-payments',

  Client = 'client',
  InsuranceApplication = 'insurance-application',
  Calculation = 'calculation',
}
