import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HoldingDataInterface } from '../models/interfaces/holding-data.interface';
import { DealerCenterGroupInterface } from '../models/interfaces/dealer-center-group.interface';
import { DealerCenter } from '../models/interfaces/dealer-center';
import { Partner } from '../models/interfaces/partner';
import { PartnerVisibility } from '../models/interfaces/partner-visibility.interface';
import { HoldingPartners } from '../models/interfaces/holding-partners.interface';
import { PersistenceService } from '@shared/services/persistence.service';

@Injectable({
  providedIn: 'root',
})
export class SettingsVisibilityService {
  constructor(private http: HttpClient, private persistenceService: PersistenceService) {}

  /**
   * Получение данных о холдинге, который находится под управлением авторизовавшегося супервайзера.
   */

  getManagedHoldingOptions(): Observable<HoldingDataInterface> {
    const url = environment.newApiUrl + '/admin/holding/current';

    return this.http.get<HoldingDataInterface>(url);
  }

  /**
   * Получение групп холдинга, который находится под управлением авторизовавшегося супервайзера.
   */

  getHoldingGroups(): Observable<DealerCenterGroupInterface[]> {
    const url = environment.newApiUrl + '/admin/holding/group';

    return this.http.get<DealerCenterGroupInterface[]>(url);
  }

  /**
   * Получение списка доступных дилерских центров.
   */

  getDealerCenterLists(): Observable<DealerCenter[]> {
    const url = environment.newApiUrl + '/admin/dealer-center';

    return this.http.get<DealerCenter[]>(url);
  }

  /**
   * Получение данных о партнёрах.
   */

  getPartners(partnerType: string, partnerName?: string): Observable<Partner[]> {
    const url = environment.newApiUrl + '/admin/partners';

    const queryParams: HttpParams = new HttpParams().appendAll({
      ['name']: partnerName ? partnerName : '',
      [partnerType]: true,
    });

    return this.http.get<Partner[]>(url, { params: queryParams });
  }

  /**
   * Получение видимости продуктов дилерских центров.
   */

  getDealerCenterVisibility(dealer_id: string): Observable<PartnerVisibility> {
    const url = environment.newApiUrl + '/admin/dealer-center/' + dealer_id + '/visibility';

    return this.http.get<PartnerVisibility>(url);
  }

  /**
   * Установка видимости продуктов дилерских центров холдинга
   */

  setHoldingVisibility(holding_id: string, body: PartnerVisibility): Observable<PartnerVisibility> {
    const url = environment.newApiUrl + '/admin/holding/' + holding_id + '/visibility';

    return this.http.post<PartnerVisibility>(url, body);
  }

  /**
   * Установка видимости продуктов дилерских центров группы
   */

  setHoldingGroupVisibility(
    group_id: string,
    body: PartnerVisibility,
  ): Observable<PartnerVisibility> {
    const url = environment.newApiUrl + '/admin/holding/group/' + group_id + '/visibility';

    return this.http.post<PartnerVisibility>(url, body);
  }

  /**
   * Установка видимости продуктов дилерских центров.
   */
  setDealerCenterVisibility(
    dealer_id: string,
    body: PartnerVisibility,
  ): Observable<PartnerVisibility> {
    const url = environment.newApiUrl + '/admin/dealer-center/' + dealer_id + '/visibility';

    return this.http.post<PartnerVisibility>(url, body);
  }

  /**
   * Получение партнеров холдинга и их продуктов.
   */
  getHoldingPartners(holding_id: string): Observable<HoldingPartners[]> {
    const url = environment.newApiUrl + '/admin/holding/' + holding_id + '/partners';

    return this.http.get<HoldingPartners[]>(url);
  }

  /** Деперсонализация клиента, для роли администратор
   * @param client_Id айдишник удаляймого клиента
   */
  deletePersonalInfo(client_id: string): any {
    const url = environment.newApiUrl + `/admin/settings/personal-info/${client_id}`;
    const token = this.persistenceService.getToken();

    return this.http
      .delete<any>(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .pipe(tap((msg) => {}));
  }
}
