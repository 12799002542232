import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, takeUntil, tap } from 'rxjs';
import {
  CurrentDealerCenter,
  RoleDataDTO,
  RolesInfo,
} from '@dealer/pages/settings-cabinet/components/wiki/wiki-models';
import { DealerService } from '@dealer/services/dealer.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '@shared/components/confirmation-dialog/confirmation-dialog.component';
import { DestroyService } from '@core/services/destroy.service';
import { SharedService } from './shared.service';
import { Router } from '@angular/router';
import { PersistenceService } from './persistence.service';

/** Сервис администрирования и работы с данными авторизованного
 * пользователя в разделе (дилерский модуль/ФИН-БРОКЕР)
 * пердпологается дополнять действиями связанными с базовой информацией об авторизованном пользователе
 *
 * этот сервис не подходит при работе с разделом/модулем client
 * дальнейшем имеет смысл переместить из глобальной области в (дилерский модуль/ФИН-БРОКЕР)
 * */
@Injectable({
  providedIn: 'root',
})
export class UserSettingsService {
  /** Данные пользователя(дилерский модуль/ФИН-БРОКЕР), роль, дилеры, настройки
   * загружаем через resolveUserRoles,
   * далее храниться тут на время жизни приложения
   * */
  public userRoles!: RoleDataDTO;

  /** Выбранный ДЦ для работы, передается в запросах */
  private _userDealer!: CurrentDealerCenter;
  public userDealer$ = new BehaviorSubject<CurrentDealerCenter>({} as CurrentDealerCenter);

  constructor(
    private dealerService: DealerService,
    private dialog: MatDialog,
    private destroy$: DestroyService,
    private sharedService: SharedService,
    private router: Router,
    private persistenceService: PersistenceService,
  ) {}

  /** Метод для UserRolesResolver
   * загружает, сохраняет и отдает пользовательскую роль,
   * используется при инициализации раздела
   * */
  public resolveUserRoles(): Observable<RoleDataDTO> {
    return this.dealerService.getUserRoles().pipe(
      this.sharedService.handleErrorAndGetErrorId(
        {
          dealer_id: this.persistenceService.get('dealer_id') ?? undefined,
          form: this.router.url,
        },
        '',
      ),
      tap((userRoles: RoleDataDTO) => {
        this.userRoles = userRoles;
        // ранее выбранный DC
        const savedDealerCenterId = localStorage.getItem('dealer_center_id');
        // ищем установленный ДЦ
        const currentDealerCenter =
          this.userRoles.dealer_center.find(
            (dealer) => dealer.dealer_center_id === savedDealerCenterId,
          ) || this.userRoles.dealer_center[0];
        // устанавливаем currentDealerCenter выбранный, в дальнейшем пользователь может поменять выбор
        this.defineDealerCenter(currentDealerCenter);
      }),
    );
  }

  /** Запомнить/Установить значение ДЦ */
  public defineDealerCenter(dealerCenter: CurrentDealerCenter): void {
    // если ДЦ не передан, работа будет заблокирована
    // перенаправляем пользователя на выход из сервиса
    if (!dealerCenter) {
      const dialog = this.dialog.open(ConfirmationDialogComponent, {
        data: {
          messageOne: 'Необходимо назначить дилерский центр.',
          messageTwo: 'Обратитесь в поддержку.',
          withoutTimer: true,
        },
        maxWidth: 535,
      });

      dialog
        .afterClosed()
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => {
          this.logout();
        });
    }

    localStorage.setItem('dealer_center_id', dealerCenter.dealer_center_id);
    this._userDealer = dealerCenter;
    this.userDealer$.next(dealerCenter);
  }

  /** получить выбранное значение ДЦ */
  public getDealerCenter(): CurrentDealerCenter {
    return this._userDealer;
  }

  /** Получить список названий ролей */
  public getUserRolesTitle(): string[] {
    if (!!this.userRoles) {
      return [...this.userRoles.roles.map((role: RolesInfo) => role.role_title)];
    } else {
      return [];
    }
  }

  /** проверка наличия роли,
   * @param userRole принимает роль которую ищем в названии ролей
   * */
  public haveCurrentRole(currentRole: string): boolean {
    return !!this.getUserRolesTitle().find((role: string) => role === currentRole);
  }

  /** Очищаем список ролей и данные по клиенту */
  public resetClient(): void {
    this.userRoles = null as unknown as RoleDataDTO;
  }

  /** Список дилеров полученный при загрузке */
  public getUserDealersList(): CurrentDealerCenter[] {
    return this.userRoles.dealer_center || ([] as CurrentDealerCenter[]);
  }

  /** Выхода из сервиса (дилерский модуль/ФИН-БРОКЕР)
   * перенаправление на страницу авторизации
   * очищение всех чувствительных данных, презагрузка
   * */
  public logout(): void {
    // очищаем хранилище, в том числе удаляем токен
    localStorage.clear();
    // перезагрузка помогает удалить данные из сервисов
    location.reload();
    // после перезагрузки BrokersAuthGuard и BrokersRolesGuard направят пользователя на страницу авторизации
  }
}
