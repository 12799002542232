import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DoCheck,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { IConfig } from 'ngx-mask';
import { BehaviorSubject, Subject, switchMap, takeUntil, timer } from 'rxjs';
import { getPatternError } from '../../helpers';
import { MatTooltip } from '@angular/material/tooltip';

@Component({
  selector: 'tes-input-basic',
  templateUrl: './tes-input-basic.component.html',
  styleUrls: ['./tes-input-basic.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TesInputBasicComponent),
      multi: true,
    },
  ],
})
export class TesInputBasicComponent
  implements ControlValueAccessor, OnInit, AfterViewChecked, OnDestroy, DoCheck
{
  @Input() public style: 'filled' | 'underline' = 'filled';
  @Input() public readonly = false;
  @Input() public readonlyInput = false;

  @Input() public label!: string;
  @Input() public placeholder!: string;
  @Input() public clearButton!: boolean;

  @Input() public value!: string;
  @Input() public titleCasePipe!: boolean;
  @Input() public trimEnd!: boolean;

  @Input() public iconSearch = false;

  /** сылка на конкретный FormControl */
  @Input() public control!: FormControl;
  /** названеи FormControl в формк */
  @Input() public controlName!: string;

  @Input() public initialTouched = false;

  @Input() public mask!: string;
  @Input() public specialCharacters!: string[];
  @Input() public prefix = '';
  @Input() public customPatterns!: IConfig['patterns'];
  @Input() public thousandSeparator!: string;
  @Input() public showMaskTyped = false;
  @Input() public separatorLimit!: string;

  @Input() public showEdited = false;
  @Input() public validation = false;
  @Input() public type = 'text';
  @Input() public length?: number;
  @Input() public showRequiredAlways = false;
  @Input() public transmittedFieldsetStyles!: any;
  @Input() public errorStatus?: string;
  @Input() public customMessage?: string;
  @Input() public hiddenInput = false;

  /** текст для ошибки 'incorrect'
   * если есть ошибка incorrect показываем incorrectMessage
   * */
  @Input() public incorrectErrMessage?: string;

  /** текст комментарий * */
  @Input() public messageComment?: string;

  @ViewChild('inputElement') private input: any;

  public disabled = false;
  public status: 'success' | 'danger' = 'success';
  public tooltipDisable$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  @ViewChild(MatTooltip) tooltip!: MatTooltip;

  public getPatternError = getPatternError;
  private _unsubscribeAll: Subject<void> = new Subject();

  get valueNumber() {
    if (typeof this.value === 'string') {
      return parseFloat(this.value.replace(/\s+/g, ''));
    } else {
      return NaN;
    }
  }

  constructor(private cdr: ChangeDetectorRef) {}

  public openTooltip(): void {
    this.tooltipDisable$.next(false);

    timer(500)
      .pipe(
        switchMap(() => {
          this.tooltip.show();
          return timer(5000);
        }),
      )
      .subscribe(() => this.tooltipDisable$.next(true));
  }
  ngAfterViewChecked() {
    if (this.initialTouched) this.onControlTouch();
  }

  public ngOnInit() {
    this.control?.statusChanges.pipe(takeUntil(this._unsubscribeAll)).subscribe((value: string) => {
      this.status = value === 'VALID' || value === 'DISABLED' ? 'success' : 'danger';
    });

    // предварительно окрашиваем required контролы при инициализации формы
    if (!!this.control?.errors?.['required']) {
      this.status = 'danger';
    }
  }

  ngDoCheck() {
    this.cdr.detectChanges();
    this.statusChange();
  }

  statusChange() {
    if (!this.readonlyInput || !this.readonly) {
      this.status = !this.control || this.control?.errors === null ? 'success' : 'danger'; // todo: subscribe on errors and if no then make status success
    } else {
      this.status = 'success';
    }
  }

  public onChange: Function = () => {};
  public onTouched: Function = () => {};

  public registerOnChange(fn: Function): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: Function): void {
    this.onTouched = fn;
  }

  public writeValue(value: string) {
    this.value = value || +value === 0 ? value : this.value;
    this.cdr.markForCheck();
  }

  public onModelChange(e: Event) {
    // bind the changes to the local value
    if (this.controlName === 'first_payment') console.log('e', e);
    this.value = (e.target as HTMLInputElement).value;
    // handle what should happen on the outside, if something changes on the inside
    if (this.controlName === 'first_payment') console.log('this.value', this.value);
    this.onChange(this.value);
    this.statusChange();
  }

  public onControlTouch() {
    this.onTouched();
    this.statusChange();
  }

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }

  editInputValue(): void {
    this.readonlyInput = false;
    this.readonly = false;
    this.showEdited = false;
  }

  /** определяем селектор для контролла */
  getCssClass(): string {
    let cssClass = '';

    // добовление селектора с иконкой поиска
    if (!!this.iconSearch) {
      cssClass = cssClass + 'tes-fieldset__icon-search';
    }

    // errorStatus явно передается
    if (!!this.errorStatus) {
      cssClass = cssClass + ' ' + this.errorStatus;
    }
    return cssClass + ' ' + this.status;
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
