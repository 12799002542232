import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable, tap } from 'rxjs';
import { SettingsVisibilityService } from '@dealer/pages/settings-cabinet/services/setting-visibility-products.service';
import { CreatePinData } from '@dealer/pages/settings-cabinet/models/interfaces/edit-dealer-centers-group-data.interface';
import { map } from 'rxjs/operators';
import {
  BasePin,
  DealerCenterPin,
  GroupPin,
  HoldingPin,
} from '@dealer/pages/settings-cabinet/components/employee-cervice-centers-page/models/dealer-center-partner-pin';
import { HoldingPartners } from '@dealer/pages/settings-cabinet/models/interfaces/holding-partners.interface';

export enum DialogEvent {
  SUBMIT,
  CLOSE,
}

@Component({
  selector: 'tes-set-pin-dealer-center-dialog',
  templateUrl: './set-pin-dealer-center-dialog.component.html',
  styleUrls: ['./set-pin-dealer-center-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SetPinDealerCenterDialogComponent {
  private readonly pinGroup: FormGroup;
  groups: string[] = [];
  partners$: Observable<string[]>;
  dealerCenters: string[] = [];
  private partners: HoldingPartners[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: CreatePinData,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<SetPinDealerCenterDialogComponent>,
    private settingsVisibilityService: SettingsVisibilityService,
  ) {
    this.pinGroup = this.fb.group({
      partnerName: [null, [Validators.required]],
      groupName: [null],
      groupCenter: [null],
      pin: [null, [Validators.required]],
    });

    this.partners$ = this.settingsVisibilityService.getHoldingPartners(this.data.partner_id).pipe(
      tap((array) => (this.partners = array)),
      map((result) => result.map((partner) => partner.info.partner_name)),
    );
    this.dealerCenters = this.data.dealer_centers.map((item) => item.dealer_center_name);
    this.groups = this.data.dealer_centers_group.map((item) => item.info.group_name);
  }

  get formGroup(): FormGroup {
    return this.pinGroup;
  }

  getControl(name: string): FormControl {
    return this.formGroup.get(name) as FormControl;
  }

  closeDialog(): void {
    this.dialogRef.close({ event: DialogEvent.CLOSE });
  }

  submitDialog(): void {
    this.dialogRef.close({ event: DialogEvent.SUBMIT, data: this.prepareValue() });
  }

  // @description Конвертация значения формы в дочерние интерфейсы BasePin
  private prepareValue(): BasePin | null {
    const { pin, partnerName, groupName, groupCenter } = this.formGroup.value;
    if (this.formGroup.valid) {
      const partnerId = this.partners.find(
        (item) => item.info.partner_name === partnerName,
      )?.partner_id;
      if (groupName && groupCenter) {
        const dealerCenter = this.data.dealer_centers.find(
          (dealer) => dealer.dealer_center_name === groupCenter,
        )?.dealer_center_id;
        return {
          partner_id: partnerId,
          partner_pin: pin,
          dealer_center_id: dealerCenter,
        } as DealerCenterPin;
      }

      if (groupName) {
        const group = this.data.dealer_centers_group.find(
          (item) => item.info.group_name === groupName,
        )?.group_id;
        return {
          partner_id: partnerId,
          partner_pin: pin,
          group_id: group,
        } as GroupPin;
      }

      return {
        partner_id: partnerId,
        partner_pin: pin,
        holding_id: this.data.partner_id,
      } as HoldingPin;
    }
    return null;
  }

  showMessage(): string | null {
    const { groupName, groupCenter } = this.formGroup.value;
    if (this.formGroup.valid) {
      if (groupName && groupCenter) {
        return 'Внимание: Пин код будет применен для выбранного ДЦ группы';
      }
      return groupName
        ? 'Внимание: Пин код будет применен для выбранной группы'
        : 'Внимание: Пин код будет применен для всех дилерских центров холдинга';
    }
    return null;
  }
}
