import { createAction, props } from '@ngrx/store';

import { BrokerActionEnum } from '../models/enums/broker-action.enum';

import { BackendErrorsType } from '../../../shared/models/types/backendErrors.type';
import { ClientConfirmAuthRequestInterface } from '../models/interfaces/client-confirm-auth-request.interface';
import { CurrentBrokerInterface } from '../models/interfaces/current-broker.interface';
import { BrokerRegisterRequestInterface } from '../models/interfaces/broker-register-request.interface';
import { CurrentClientConfirmResponseInterface } from '../models/interfaces/current-client-confirm-response.interface';
import { CurrentClientInterface } from '../models/interfaces/current-client.interface';
import { UserAuthRequestType } from '../models/types/user-auth-request.type';
import { ErrorClientInfo } from '@shared/models/interfaces/error-full-info.interface';

export const authBrokerAction = createAction(
  BrokerActionEnum.BROKER_AUTH,
  props<{ request: BrokerRegisterRequestInterface }>(),
);

export const authBrokerActionWithError = createAction(
  BrokerActionEnum.BROKER_AUTH_WITH_ERROR,
  props<{ request: BrokerRegisterRequestInterface; payload: ErrorClientInfo }>(),
);

export const authBrokerSuccessAction = createAction(
  BrokerActionEnum.BROKER_AUTH_SUCCESS,
  props<{ currentBroker: CurrentBrokerInterface }>(),
);

export const authBrokerFailureAction = createAction(
  BrokerActionEnum.BROKER_AUTH_FAILURE,
  props<{ errors: BackendErrorsType }>(),
);

export const clientAuthAction = createAction(
  BrokerActionEnum.CLIENT_AUTH_INDEPENDENTLY,
  props<{ request: UserAuthRequestType }>(),
);

export const clientAuthSuccessAction = createAction(
  BrokerActionEnum.CLIENT_AUTH_INDEPENDENTLY_SUCCESS,
  props<{ currentUser: CurrentClientInterface }>(),
);

export const clientAuthFailureAction = createAction(
  BrokerActionEnum.CLIENT_AUTH_INDEPENDENTLY_FAILURE,
  props<{ errors: BackendErrorsType }>(),
);

export const clientConfirmationAuthAction = createAction(
  BrokerActionEnum.CLIENT_CONFIRM_AUTH,
  props<{ request: ClientConfirmAuthRequestInterface }>(),
);

export const clientConfirmationSuccessAction = createAction(
  BrokerActionEnum.CLIENT_CONFIRM_AUTH_SUCCESS,
  props<{ response: CurrentClientConfirmResponseInterface }>(),
);

export const clientConfirmationFailureAction = createAction(
  BrokerActionEnum.CLIENT_CONFIRM_AUTH_FAILURE,
  props<{ errors: BackendErrorsType }>(),
);
