import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { PersistenceService } from '@shared/services/persistence.service';
import { environment } from 'src/environments/environment';
import { DadataRequestBody } from '@client/models/interfaces/dadata-request-body';
import { DadataResponse } from '@client/models/interfaces/dadata-response';
import { DealerAuthenticateAny } from '../pages/calculation-loan-page/models/interfaces/dealer-authenticateAny.interface';
import { FilesUtiles } from '../pages/calculation-loan-page/models/interfaces/filesUtiles.interface';
import { PassportIssuedByResponse } from '@client/models/interfaces/passport-issued-by-response';
import { PassportIssuedByRequest } from '@client/models/interfaces/passport-issued-by-request';
import { RoleDataDTO } from '../pages/settings-cabinet/components/wiki/wiki-models';
import { LoanLimits } from '@shared/models/interfaces/costCarLimits.interface';
import { BankNamesResponse } from '../pages/calculation-loan-page/models/interfaces/bank-name-response';
import { ClientPDPAConfirmationInterface } from '../pages/calculation-loan-page/models/interfaces/client-PDPA-Confirmation.interface';
import { ClientPDPAConfirmationResponseInterface } from '../pages/calculation-loan-page/models/interfaces/client-PDPA-Confirmation-response.interface';
import { RGUTarif } from '../pages/calculation-loan-page/models/interfaces/rgu-tarif.interface';
import { FullAdminClient } from '@shared/models/interfaces';
import { GetClientsParams } from '@shared/models/interfaces/request-models.interface';
import { ResponsePagination } from '@shared/models/interfaces/response-models.interface';
import { FZPlan } from '../pages/calculation-loan-page/models/interfaces/fz-plan.interface';
import { CatalogJob } from '@shared/models/types/catalog-job.type';

@Injectable({ providedIn: 'root' })
export class DealerService {
  private newClientListId = new BehaviorSubject<string>('');

  public newClientListId$ = this.newClientListId.asObservable();
  // Данная переменная нужна для добавления валидации полей Vin и ДКП
  // Когда заявка одорена и пользователь нажимает "изменить параметры" эта переменная становится true и поля в этом
  // случае обязательны для заполнения
  public isChangedParams = new BehaviorSubject(false);

  constructor(private http: HttpClient, private persistenceService: PersistenceService) {}

  /**
   * Передача id дилерского центра для получения клиентов
   */
  public setNewClientListId(id: string) {
    this.newClientListId.next(id);
  }

  public getIdClient(): Observable<DealerAuthenticateAny> {
    const url = environment.newApiUrl + '/auth/any/authenticateAny';
    const token = this.persistenceService.getToken();
    return this.http.post<any>(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  /**
   * Получение подсказок при заполнении поля наименования органа, выдавшего паспорт
   */
  public getPassportIssuedBySuggestion(
    body: PassportIssuedByRequest,
  ): Observable<PassportIssuedByResponse> {
    const url = 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/fms_unit';
    const token = '9dea286bac8bb99f01fafc6ac6d2fa3a10e8f4f4';

    return this.http.post<PassportIssuedByResponse>(url, body, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Token ' + token,
      },
    });
  }

  /**
   * Получение подсказок при заполнении адреса
   */
  public getDadataSuggestion(body: DadataRequestBody): Observable<DadataResponse> {
    const url = 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address';
    const token = '9dea286bac8bb99f01fafc6ac6d2fa3a10e8f4f4';

    return this.http.post<DadataResponse>(url, body, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Token ' + token,
      },
    });
  }

  public getRegions(): Observable<any[]> {
    const httpHeaders: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const url = environment.newApiUrl + '/common-utils/catalog-region';

    return this.http.get<any>(url, { headers: httpHeaders });
  }

  /**
   * Получение подсказок при заполнении названия банка
   */
  public getDadataBankName(value: string): Observable<BankNamesResponse> {
    const url = 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/bank';
    const token = '9dea286bac8bb99f01fafc6ac6d2fa3a10e8f4f4';

    const body = { query: value.replace(/\*|%|#|&|\$\«\»\//g, '') };

    return this.http.post<BankNamesResponse>(url, body, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Token ' + token,
      },
    });
  }

  /** создание и обновление данных хранилища */
  public createTempStorage(body: any, dealer_id: string): Observable<any> {
    const url = environment.newApiUrl + '/common-utils/temp-stores?client_id=' + dealer_id;
    const client_id = this.persistenceService.getClientId();
    const token = this.persistenceService.getToken();

    return this.http.put<any>(url, body, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        filter_key: client_id,
      },
    });
  }

  public getTempStorage(dealer_id: any): Observable<any> {
    const url = environment.newApiUrl + '/common-utils/temp-stores?client_id=' + dealer_id;
    const token = this.persistenceService.getToken();
    const client_id = this.persistenceService.getClientId();

    return this.http.get<any>(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        filter_key: client_id,
      },
    });
  }

  /** загрузка файлов в yandexcloud
   * @param file: сохранемый файл формата FilesUtiles
   * @param dealer_id: дилер которому принадлежит файл
   * */
  public yandexCloudFileUpload(file: FilesUtiles, dealer_id: string): Observable<FilesUtiles> {
    const url = environment.newApiUrl + '/common-utils/files?client_id=' + dealer_id;
    const token = this.persistenceService.getToken();

    return this.http.post<FilesUtiles>(url, file, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  /**
   * Отправка файлов согласия с обработкой ПД в удаленное хранилище
   */
  public converterPDPAFile(file: FilesUtiles, client_id: string): Observable<FilesUtiles> {
    const url = environment.newApiUrl + '/common-utils/pdpa/' + client_id;
    const token = this.persistenceService.getToken();

    return this.http.post<FilesUtiles>(url, file, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  /**
   * Отправка анкеты клиента в удаленное хранилище
   */
  public converterClientSurvey(file: FilesUtiles, client_id: string): Observable<FilesUtiles> {
    const url = environment.newApiUrl + '/common-utils/survey/' + client_id;
    const token = this.persistenceService.getToken();

    return this.http.post<FilesUtiles>(url, file, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  /**
   * Отправка анкеты-соглашения для кредита в удаленное хранилище
   */
  public converterClientAgreement(file: FilesUtiles, client_id: string): Observable<FilesUtiles> {
    const url = environment.newApiUrl + '/common-utils/agreement/' + client_id;
    const token = this.persistenceService.getToken();

    return this.http.post<FilesUtiles>(url, file, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  /**
   * Отправка заявления клиента в удаленное хранилище
   */
  public converterClientStatement(file: FilesUtiles, client_id: string): Observable<FilesUtiles> {
    const url = environment.newApiUrl + '/common-utils/statement/' + client_id;
    const token = this.persistenceService.getToken();

    return this.http.post<FilesUtiles>(url, file, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  /**
   * отправка смс клиенту для подтверждения операции
   */
  public sendAccessRequestMessage(body: any): Observable<any> {
    const url = environment.newApiUrl + '/auth/dealer/clientSendAccessRequestMessage';
    const token = this.persistenceService.getToken();

    return this.http.post<any>(url, body, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  /**
   * Подтверждение согласия клиента
   */
  public clientAccessMessageConfirmation(body: any): Observable<any> {
    const url = environment.newApiUrl + '/auth/dealer/clientAccessMessageConfirmation';
    const token = this.persistenceService.getToken();

    return this.http.post<any>(url, body, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  /**
   * Проверка роли авторизовавшегося пользователя
   */
  public getUserRoles(): Observable<RoleDataDTO> {
    const url = environment.newApiUrl + '/admin/check-roles';
    const token = this.persistenceService.getToken();
    return this.http.get<RoleDataDTO>(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  /**
   * Получение кредитных ограничений
   */
  public getLoanLimits(): Observable<LoanLimits> {
    const url = environment.newApiUrl + '/common-utils/limits';
    const token = this.persistenceService.getToken();
    return this.http.get<LoanLimits>(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  /**
   * Получение пакетов РГУ
   */
  public getRGUTarifs(partner_id: string): Observable<RGUTarif[]> {
    const url = environment.newApiUrl + '/common-utils/rgu/' + partner_id;
    const token = this.persistenceService.getToken();
    return this.http.get<RGUTarif[]>(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  /**
   * Получение страховых тарифов
   */
  public getInsurancePlans(partner_id: string): Observable<FZPlan[]> {
    const url = environment.newApiUrl + '/common-utils/fz/' + partner_id;
    const token = this.persistenceService.getToken();
    return this.http.get<FZPlan[]>(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  /**
   * Подтверждение подписи клиентом согласия на обработку ПД
   */
  public sendPDPAConfirmation(
    body: ClientPDPAConfirmationInterface,
  ): Observable<ClientPDPAConfirmationResponseInterface> {
    const url = environment.newApiUrl + '/auth/dealer/clientPDPAConfirmation';

    return this.http.post<ClientPDPAConfirmationResponseInterface>(url, body);
  }

  /** получение списка клиентов
   * @param GetClientsParams
   * */
  public getClients(
    body: GetClientsParams,
  ): Observable<ResponsePagination<Array<FullAdminClient>>> {
    const url = environment.newApiUrl + '/auth/dealer/getClients';
    const token = this.persistenceService.getToken();
    return this.http.post<ResponsePagination<Array<FullAdminClient>>>(url, body, {
      headers: {
        accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
  }

  /**
   * Получение всех клиентов дилерского центра
   */
  public getDealerClients(dealer_center_id?: string): Observable<any> {
    const url = environment.newApiUrl + '/auth/service/getDealerCenterClients';
    const token = this.persistenceService.getToken();
    return this.http.get<any>(url, {
      headers: {
        accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
      params: dealer_center_id ? { dealer_center_id } : {},
    });
  }

  //получение типов занятости

  public getJobs(): Observable<CatalogJob[]> {
    const url = environment.newApiUrl + '/common-utils/catalog-job';
    const token = this.persistenceService.getToken();
    return this.http.get<CatalogJob[]>(url, {
      headers: {
        accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
  }
}
