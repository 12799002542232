import { Injectable, OnDestroy } from '@angular/core';
import { ReplaySubject } from 'rxjs';

/**
 * @tutorial
 * Для того, чтобы использовать данный сервис для отписки, в первую очередь необходимо запровайдить:
 * providers: [DestroyService]
 * После того, как мы запровайдили сервис, нужно объявить его в конструкторе как зависимость напрямую или через @Inject()
 */
@Injectable({
  providedIn: 'root',
})
export class DestroyService extends ReplaySubject<void> implements OnDestroy {
  constructor() {
    super(1);
  }

  ngOnDestroy(): void {
    this.next();
    this.complete();
  }
}
