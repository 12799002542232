import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';

import { EMPTY, Observable, catchError, throwError } from 'rxjs';

import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { routingPathEnum } from '@shared/models';
import { PersistenceService } from '../../shared/services/persistence.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '@shared/components/confirmation-dialog/confirmation-dialog.component';
import { SharedService } from '@shared/services/shared.service';
import { authBrokerFailureAction } from '@auth/broker-auth-page/store/broker-auth.action';
import { Store } from '@ngrx/store';

@Injectable()
export class BrokerTokenInterceptor implements HttpInterceptor {
  constructor(
    private persistenceService: PersistenceService,
    private router: Router,
    private location: Location,
    private dialog: MatDialog,
    private sharedService: SharedService,
    private store: Store,
  ) {}

  private handleAuthError(err: HttpErrorResponse): Observable<any> {
    if (this.location.path() !== '/auth-broker')
      this.persistenceService.set('lastUrl', this.location.path());
    if (err.status === 401) {
      this.router.navigateByUrl(`/${routingPathEnum.BrokerAuthentication}`);
      localStorage.removeItem('accessToken');
      return EMPTY;
    }
    if (err.status === 403) {
      if (this.location.path() === '/auth-broker') {
        this.sharedService.openDialog(err.error.message);
        this.store.dispatch(authBrokerFailureAction({ errors: err.error }));
        return EMPTY;
      } else {
        localStorage.removeItem('lastUrl');

        if (this.router.url.includes('personal-account')) {
          this.router.navigateByUrl(
            `/${routingPathEnum.BrokerAuthentication}/${routingPathEnum.Settings}/${routingPathEnum.PersonalAccount}`,
          );
        } else {
          this.router.navigateByUrl(
            `/${routingPathEnum.BrokerAuthentication}/${routingPathEnum.ClientFilterAuth}`,
          );
        }

        if (!!!this.dialog.openDialogs.length) {
          const dialog = this.dialog.open(ConfirmationDialogComponent, {
            data: {
              messageOne: 'У вас нет прав доступа к этому контенту',
              confirmButton: true,
              withoutTimer: true,
            },
            maxWidth: 500,
          });

          setTimeout(() => {
            dialog.close();
          }, 5000);
        }
        return EMPTY;
      }
    }
    return throwError(() => {
      return err;
    });
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const token = this.persistenceService.getToken();
    if (request.url.indexOf('dadata') < 0) {
      if (token) {
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${token}`,
          },
        });
      }
    }

    return next.handle(request).pipe(catchError((err) => this.handleAuthError(err)));
  }
}
