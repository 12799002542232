export enum BrokerActionEnum {
  BROKER_AUTH = '[BROKER_AUTH] Broker auth',
  BROKER_AUTH_SUCCESS = '[BROKER_AUTH] Broker auth success',
  BROKER_AUTH_FAILURE = '[BROKER_AUTH] Broker auth failure',

  CLIENT_AUTH_INDEPENDENTLY = '[BROKER_AUTH] Client auth independently',
  CLIENT_AUTH_INDEPENDENTLY_SUCCESS = '[BROKER_AUTH] Client successfully auth independently',
  CLIENT_AUTH_INDEPENDENTLY_FAILURE = '[BROKER_AUTH] Client unsuccessfully auth independently',

  CLIENT_CONFIRM_AUTH = '[BROKER_AUTH] Client confirm auth',
  CLIENT_CONFIRM_AUTH_SUCCESS = '[BROKER_AUTH] Client successfully confirmed auth',
  CLIENT_CONFIRM_AUTH_FAILURE = '[BROKER_AUTH] Client unsuccessfully confirmed auth',
}
