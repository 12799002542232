import { Injectable } from '@angular/core';
import { CanLoad, Router } from '@angular/router';

import { Observable } from 'rxjs';
import { PersistenceService } from 'src/app/shared/services/persistence.service';
import { routingPathEnum } from '@shared/models';
/** проверка авторизации в системе фин-брокер(дилер)*/
@Injectable()
export class BrokersAuthGuard implements CanLoad {
  constructor(
    private persistenceService: PersistenceService,
    private router: Router,
  ) {}
  public canLoad(): Observable<boolean> | boolean {
    // при физическом наличии токена, разрешаем доступ
    // без проверки на актуальность
    if (this.persistenceService.getToken()) {
      return true;
    }

    // если токена нет редиректим пользователя на страницу авторизации
    this.router.navigateByUrl(`/${routingPathEnum.BrokerAuthentication}`)
    return false;
  }
}
