<fieldset
  *ngIf="!readonly; else readonlyMode"
  class="tes-fieldset"
  [ngClass]="getCssClass()"
  [ngStyle]="transmittedFieldsetStyles">
  <label *ngIf="label" class="tes-fieldset-label">
    <small class="text-secondary">{{ label }}</small>
  </label>

  <img
    *ngIf="!!iconSearch"
    class="search_icon"
    src="/assets/images/search_icon.svg"
    alt="search-icon" />

  <input
    #inputElement
    [type]="type"
    class="tes-fieldset-basic-input"
    [ngClass]="['tes-fieldset-basic-input-' + style]"
    [ngModel]="value | trimAndTitleCasePipe : titleCasePipe : trimEnd"
    [disabled]="disabled"
    [placeholder]="placeholder || ''"
    [mask]="mask"
    [prefix]="prefix"
    [patterns]="customPatterns"
    (input)="onModelChange($event)"
    (blur)="onControlTouch()"
    [readonly]="readonlyInput"
    [thousandSeparator]="thousandSeparator"
    [showMaskTyped]="showMaskTyped"
    [separatorLimit]="separatorLimit"
    [maxlength]="length" />

  <div class="tes-fieldset-feedback" *ngIf="messageComment">
    <ul>
      <li>{{ messageComment }}</li>
    </ul>
  </div>

  <div
    class="tes-fieldset-feedback"
    *ngIf="
      !messageComment && control?.invalid && (control?.dirty || control?.touched) && control.errors
    ">
    <ul>
      <ng-container *ngIf="control.hasError('incorrect') && incorrectErrMessage">
        <li>{{ incorrectErrMessage }}</li>
      </ng-container>

      <ng-container *ngIf="control.hasError('required')">
        <li>Обязательное поле</li>
      </ng-container>

      <ng-container *ngIf="control.hasError('pattern')">
        <li>{{ getPatternError(control) }}</li>
      </ng-container>

      <ng-container *ngIf="control.hasError('minlength')">
        <li>
          Длина должна быть не меньше {{ control.errors['minlength']?.requiredLength }} символов
        </li>
      </ng-container>

      <ng-container *ngIf="control.hasError('maxlength')">
        <li>
          Длина должна быть не больше {{ control.errors['maxlength']?.requiredLength }} символов
        </li>
      </ng-container>

      <ng-container *ngIf="control.hasError('max')">
        <li>
          Значение должно быть не больше <br />{{ control.errors['max']?.max | separatorNumber }}
        </li>
      </ng-container>

      <ng-container *ngIf="control.hasError('min')">
        <li>Значение должно быть не меньше {{ control.errors['min']?.min | separatorNumber }}</li>
      </ng-container>

      <ng-container *ngIf="control.hasError('yearLastNameChange')">
        <li>Значение должно быть в промежутке между годом рождения и текущим годом</li>
      </ng-container>

      <ng-container *ngIf="control.hasError('invalidCarPrice')">
        <li>
          Стоимость ТС должна быть от&nbsp;{{
            control.errors['invalidCarPrice']?.minCost | mask : 'separator' : '&nbsp;'
          }}
          до&nbsp;{{ control.errors['invalidCarPrice']?.maxCost | mask : 'separator' : '&nbsp;' }}
        </li>
      </ng-container>

      <ng-container *ngIf="control.hasError('phoneNumberMinLength')">
        <li>Номер телефона должен содержать не менее 10 цифр</li>
      </ng-container>

      <ng-container *ngIf="control.hasError('driverLicenseMinLength')">
        <li>Значение должно содержать не менее 10 цифр</li>
      </ng-container>

      <ng-container *ngIf="control.hasError('driverIssuedByMinLength')">
        <li>Значение должно содержать не менее 4 цифр</li>
      </ng-container>

      <ng-container *ngIf="control.hasError('customMessage')">
        <li>{{ control.getError('customMessage') }}</li>
      </ng-container>

      <ng-container *ngIf="control.hasError('lessThanMinCarYearDate')">
        <li>Авто не старше прошлого года</li>
      </ng-container>

      <ng-container *ngIf="control.hasError('moreThanCarYearToday')">
        <li>Значение должно быть не более 2023 года</li>
      </ng-container>

      <ng-container *ngIf="control.hasError('lessThanMinUsedCarYearDate')">
        <li>Авто не старше 1900 года</li>
      </ng-container>

      <ng-container *ngIf="control.hasError('wrongCode')">
        <li>Код введен неверно</li>
      </ng-container>
      <!-- TODO: Следует удалить эту ошибку после реализации https://tracker.yandex.ru/FI-1669 -->
      <ng-container *ngIf="control.hasError('maxFirstPayment')">
        <li>ПВ должен быть от 0 до 99% от стоимости ТС</li>
      </ng-container>
      <ng-container *ngIf="control.hasError('invalidFirstPayment')">
        <li>
          ПВ должен быть от&nbsp;{{
            control.errors['invalidFirstPayment']?.minPV | mask : 'separator' : '&nbsp;'
          }}
          до&nbsp;{{
            control.errors['invalidFirstPayment']?.maxPV | mask : 'separator' : '&nbsp;'
          }}% от стоимости авто
        </li>
      </ng-container>
      <ng-container *ngIf="control.hasError('limitLoanSum')">
        <li>
          Сумма кредита должна быть от&nbsp;{{
            control.errors['limitLoanSum']?.minCost | mask : 'separator' : '&nbsp;'
          }}
          до&nbsp;{{ control.errors['limitLoanSum']?.maxCost | mask : 'separator' : '&nbsp;' }}
        </li>
      </ng-container>
      <ng-container *ngIf="control.hasError('emergencyPhoneNumber')">
        <li>Номер контакта на экстренный случай не должен совпадать с номером клиента</li>
      </ng-container>
      <ng-container *ngIf="control.hasError('ptsLength')">
        <li>ПТС должен содержать 10 символов в формате “00АА 000000”</li>
      </ng-container>
      <ng-container *ngIf="control.hasError('EptsLength')">
        <li>ЭПТС должен содержать 15 символов в формате “000000000000000”</li>
      </ng-container>
    </ul>
  </div>
  <div
    class="tes-fieldset-feedback"
    *ngIf="!messageComment && !errorStatus && control?.disabled && control.errors">
    <ul>
      <ng-container *ngIf="control.hasError('customMessage')">
        <li>{{ control.getError('customMessage') }}</li>
      </ng-container>
    </ul>
  </div>
  <div
    class="tes-fieldset-feedback"
    *ngIf="!messageComment && errorStatus === 'danger' && customMessage">
    <ul>
      <li>{{ customMessage }}</li>
    </ul>
  </div>
</fieldset>

<ng-template #readonlyMode>
  <label
    *ngIf="control"
    class="tes-fieldset-readonly"
    [ngClass]="{
      'is-edited': showEdited,
      'has-required-error':
        control.invalid &&
        (control.dirty || control.touched) &&
        control.errors &&
        showRequiredAlways
    }">
    <small class="text-secondary ps-1">{{ label }}</small>
    <input
      type="text"
      class="tes-value-readonly"
      readonly
      [ngModel]="value"
      [mask]="mask"
      [patterns]="customPatterns"
      [prefix]="prefix"
      [matTooltip]="value"
      [matTooltipDisabled]="tooltipDisable$ | async"
      (click)="openTooltip()"
      matTooltipPosition="below" />
    <button class="edited-edit button" (click)="editInputValue()" *ngIf="showEdited">
      <mat-icon>border_color</mat-icon>
    </button>
  </label>
</ng-template>
