import { Injectable } from '@angular/core';
import { CanLoad, Router } from '@angular/router';

import { Observable } from 'rxjs';

import { PersistenceService } from '../../services/persistence.service';

@Injectable()
export class ClientAuthGuard implements CanLoad {
  constructor(private router: Router, private persistenceService: PersistenceService) {}

  public canLoad(): Observable<boolean> | boolean {
    if (this.persistenceService.getToken(true) && this.persistenceService.getClientId()) {
      return true;
    } else if (!this.persistenceService.getToken(true) && this.persistenceService.getClientId()) {
      this.router.navigateByUrl(`/client`);
      return false;
    } else {
      this.router.navigateByUrl(`/client`);
      return false;
    }
  }
}
