import { Component, Input } from '@angular/core';

@Component({
  selector: 'tes-button',
  templateUrl: './tes-button.component.html',
  styleUrls: ['./tes-button.component.scss'],
})
export class TesButtonComponent {
  @Input() title!: string | number;
  @Input() color: 'primary' | 'secondary' | 'helper' | 'aquamarine' = 'primary';
  @Input() icon!: string;
  @Input() iconPlace: 'pre' | 'post' = 'pre';
  @Input() disabled = false;
  @Input() public transmittedStyles!: any;
}
