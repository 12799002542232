import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import {
  BehaviorSubject,
  catchError,
  EMPTY,
  MonoTypeOperatorFunction,
  Observable,
  Subject,
  switchMap,
  tap,
  throwError,
} from 'rxjs';
import { ConfirmationDialogComponent } from '@shared/components/confirmation-dialog/confirmation-dialog.component';
import { routingPathEnum } from '../models';
import { PersistenceService } from './persistence.service';
import {
  ErrorClientInfo,
  ErrorFullInfo,
  ErrorRequestInfo,
} from '../models/interfaces/error-full-info.interface';
import { ErrorResponseInfo } from '../models/interfaces/error-response-info.interface';
import { environment } from 'src/environments/environment';
import { ConfirmationDialogData } from '@shared/components/confirmation-dialog/confirmation-dialog.component';

const CLIENT_ERRORS_STATUSE_CODES: number[] = [400, 403, 404, 422];
const SERVER_ERRORS_STATUSE_CODES: number[] = [500, 502, 503];

@Injectable({ providedIn: 'root' })
export class SharedService {
  sidebarData$: BehaviorSubject<{ header: string; data: any[] }> = new BehaviorSubject({
    header: '',
    data: Array.of(),
  });
  clientSurveyStepBarData$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  carOptionsStepBarData$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  _userActionOccured: Subject<void> = new Subject();

  constructor(
    private router: Router,
    private persistenceService: PersistenceService,
    private dialog: MatDialog,
    private http: HttpClient,
  ) {}

  /// Use this refresh$ observable in data services to indicate when data refresh is needed
  private _refresh = new BehaviorSubject<void>(undefined);

  get refresh$(): Observable<any> {
    return this._refresh.asObservable();
  }

  get userActionOccured(): Observable<void> {
    return this._userActionOccured.asObservable();
  }

  public notifyUserAction() {
    this._userActionOccured.next();
  }

  public logOutUser() {
    this.persistenceService.set('lastUrl', this.router.url);
    this.router.navigateByUrl(`/${routingPathEnum.BrokerAuthentication}`);
    localStorage.removeItem('accessToken');
  }

  reloadCurrentRoute() {
    const currentUrl = this.router.url;
    this.router.navigateByUrl('', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentUrl]);
    });
    this._refresh.next();
  }

  emitRefresh() {
    this._refresh.next();
  }

  loadingError(error: any) {
    if (error) {
      this.openDialog('Ошибка при загрузке данных');
    }
  }

  actionSuccess(done: boolean, message: string) {
    if (done) {
      this.openDialog(message);
    }
  }

  actionError(error: any, message: string) {
    if (error) {
      this.openDialog(message);
    }
  }

  openDialog(message: string, errorId?: string) {
    const dialogData: Pick<
      ConfirmationDialogData,
      'messageOne' | 'confirmButton' | 'buttonTitle' | 'withoutTimer' | 'messageTwo'
    > = {
      messageOne: message,
      confirmButton: true,
      buttonTitle: 'Ок',
      withoutTimer: true,
      messageTwo: '',
    };

    if (errorId) {
      dialogData.messageTwo = `Пожалуйста, обратитесь в тех.поддержку с ID ошибки = ${errorId}`;
    }

    this.dialog.open(ConfirmationDialogComponent, {
      data: dialogData,
    });
  }

  catchError<T>(
    callbackFn?: () => Observable<never>,
    requestData?: ErrorRequestInfo,
    errorBody?: ErrorClientInfo,
  ): MonoTypeOperatorFunction<T> {
    return catchError((response: HttpErrorResponse) => {
      console.error(response);
      if (response && errorBody && requestData) {
        const bodyError: ErrorFullInfo = Object.assign({}, errorBody, {
          status: requestData.status,
          request_id: requestData.request_id,
          response: requestData.response,
          request: requestData.request,
          url: requestData.url,
        });

        return this.saveErrorInfo(bodyError).pipe(
          switchMap((errorResponse: ErrorResponseInfo) => {
            this.showErrorMessage(response, errorResponse.error_id);
            return callbackFn ? callbackFn() : EMPTY;
          }),
        );
      }

      if (response) {
        this.showErrorMessage(response);
      }

      return callbackFn ? callbackFn() : EMPTY;
    });
  }

  handleErrorAndGetErrorId<T>(
    errorInfoProvider: ErrorClientInfo,
    requestPayload: Record<string, any> | string,
    callbackFn: () => Observable<never> = () => EMPTY,
  ): MonoTypeOperatorFunction<T> {
    return catchError((error: HttpErrorResponse) => {
      return this.createCatchErrorPipeline<T>(
        errorInfoProvider,
        requestPayload,
        callbackFn,
      )(throwError(() => error));
    });
  }

  private showErrorMessage(errorResponse: HttpErrorResponse, errorId?: string): void {
    const errorStatus = errorResponse.status;

    const errorErrorMessage = this.getErrorMessage(errorResponse.error?.error);
    if (errorErrorMessage) {
      this.openDialog(errorErrorMessage, errorId);
      return;
    }

    if (SERVER_ERRORS_STATUSE_CODES.includes(errorStatus)) {
      this.openDialog('Сервис временно недоступен, попробуйте позже', errorId);
      return;
    }

    const errorMessage = this.getErrorMessage(errorResponse.error);
    if (errorMessage && CLIENT_ERRORS_STATUSE_CODES.includes(errorStatus)) {
      this.openDialog(errorMessage, errorId);
      return;
    }

    const errorString = typeof errorResponse.error === 'string' ? errorResponse.error : null;
    if (errorString && CLIENT_ERRORS_STATUSE_CODES.includes(errorStatus)) {
      this.openDialog(errorString, errorId);
      return;
    }

    this.openDialog('Что-то пошло не так. Пожалуйста, попробуйте позже.', errorId);
  }

  private getErrorMessage(errorData: any | undefined): string | null {
    if (typeof errorData === 'string') {
      return errorData;
    }

    if (typeof errorData?.message === 'string') {
      return errorData.message;
    }

    if (Array.isArray(errorData?.data)) {
      return errorData.data[0];
    }

    return null;
  }

  private createRequestInterceptor<T>(requestData: ErrorRequestInfo): MonoTypeOperatorFunction<T> {
    return tap<T>({
      error: (error: HttpErrorResponse) => {
        requestData.status = error.status;
        requestData.request_id = error.headers?.get('x-request-id') ?? undefined;
        requestData.response = typeof error === 'string' ? error : JSON.stringify(error);
        requestData.url = error.url ?? undefined;
      },
    });
  }

  private createCatchErrorPipeline<T>(
    errorBody: ErrorClientInfo,
    request: Record<string, any> | string,
    callbackFn?: () => Observable<never>,
  ): MonoTypeOperatorFunction<T> {
    const requestData: ErrorRequestInfo = {
      status: null,
      request_id: '',
      response: '',
      request: typeof request === 'string' ? request : JSON.stringify(request),
      url: '',
    };
    return (source: Observable<T>) =>
      source.pipe(
        this.createRequestInterceptor<T>(requestData),
        this.catchError<T>(callbackFn, requestData, errorBody),
      );
  }

  // Сохранение информации об ошибке
  private saveErrorInfo(body: ErrorFullInfo): Observable<ErrorResponseInfo> {
    const url = environment.newApiUrl + '/common-utils/error';

    return this.http.post<ErrorResponseInfo>(url, body);
  }
}
