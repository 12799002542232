<fieldset class="tes-fieldset" [ngClass]="status" *ngIf="!readonly; else readonlyMode">
  <label *ngIf="label" class="tes-fieldset-label">
    <small class="text-secondary">{{ label }}</small>
  </label>
  <select
    class="tes-fieldset-basic-select"
    [ngClass]="['tes-fieldset-basic-select-' + style]"
    (ngModelChange)="onModelChange($event)"
    [ngModel]="control.value"
    [disabled]="disabled"
    (focusout)="onControlTouch()">
    <option *ngIf="placeholder" value="" disabled selected>{{ placeholder }}</option>
    <option *ngIf="allowEmptyValue" [ngValue]="null"></option>
    <ng-container *ngIf="isObjectList(); else stringList">
      <option *ngFor="let item of list" [ngValue]="item[valueField]">
        {{ item[displayField] }}
      </option>
    </ng-container>
    <ng-template #stringList>
      <option *ngFor="let item of list" [ngValue]="item">{{ item }}</option>
    </ng-template>
  </select>
  <div
    class="tes-fieldset-feedback"
    *ngIf="control?.invalid && (control?.dirty || control?.touched) && control.errors">
    <ng-container *ngIf="control.hasError('required')"> Обязательное поле</ng-container>
    <ng-container *ngIf="control.hasError('moreThanMinNewCarYearDate')">
      Новый автомобиль может быть текущего или прошлого года выпуска
    </ng-container>
    <ng-container *ngIf="control.hasError('moreThanMinUsedCarYearDate')">
      Автомобиль с пробегом – не старше 15 лет
    </ng-container>
    <ng-container *ngIf="control.hasError('customMessage')">{{
      control.getError('customMessage')
    }}</ng-container>
  </div>
</fieldset>

<ng-template #readonlyMode>
  <label
    class="tes-fieldset-label-readonly"
    [class]="{
      'has-required-error':
        control.invalid &&
        (control.dirty || control.touched) &&
        control.errors &&
        showRequiredAlways
    }">
    <small class="text-secondary ps-1">{{ label }}</small>
    <span class="tes-value-readonly">{{ isObjectList() ? value[displayField] : value }}</span>
  </label>
</ng-template>
