import { Pipe, PipeTransform } from '@angular/core';
import { TitleCasePipe } from '@angular/common';

@Pipe({
  name: 'trimAndTitleCasePipe',
})
export class TrimAndTitleCasePipe implements PipeTransform {
  transform(value: string, titleCasePipe: boolean, trimEnd: boolean): string {
    let transformedValue = value;

    if (titleCasePipe) {
      const titleCasePipeInstance = new TitleCasePipe();
      transformedValue = titleCasePipeInstance.transform(transformedValue);

      // Дополнительная логика для обработки дефисов и установки большой буквы сразу после дефиса
      transformedValue = transformedValue?.replace(
        /-([а-яё])/g,
        (_, char) => `-${char.toUpperCase()}`,
      );
    }

    if (trimEnd) {
      transformedValue = transformedValue?.trimEnd();
    }

    return transformedValue;
  }
}
