import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import moment from 'moment';

export class DateValidator {
  static isDateLessThanToday(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const today: Date = new Date();

      if (new Date(control.value) > today) return { moreThanToday: true };
      return null;
    };
  }

  static isDateLessOrEqualToday(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value: Date = new Date(control.value);
      const today: Date = new Date();
      value.setHours(0, 0, 0, 0);
      today.setHours(0, 0, 0, 0);

      if (value >= today) return { moreOrEqualToday: true };
      return null;
    };
  }

  static isDateValid(control: AbstractControl): ValidationErrors | null {
    if (moment(control.value).isValid() || control.value === null) {
      return null;
    }
    return { invalidDate: true };
  }
}
