<h2 mat-dialog-title class="dialog-header">
  Настроить ПИН
</h2>
<mat-dialog-content class="form-items" [formGroup]='formGroup'>
  <tes-select
    [control]="getControl('partnerName')"
    formControlName="partnerName"
    label="Название партнера"
    [allowEmptyValue]='false'
    [list]="partners$ | async"
  ></tes-select>
  <tes-select
    [control]="getControl('groupName')"
    formControlName="groupName"
    label="Название группы"
    [allowEmptyValue]='false'
    [list]="groups"
  ></tes-select>
  <tes-select
    [control]="getControl('groupCenter')"
    formControlName="groupCenter"
    label="ДЦ группы"
    [allowEmptyValue]='false'
    [list]="dealerCenters"
  ></tes-select>
  <tes-input-basic formControlName="pin" label="Пин код" [control]="getControl('pin')"></tes-input-basic>
  <p *ngIf="showMessage() as message">
    {{message}}
  </p>
</mat-dialog-content>
<mat-dialog-actions align="center" class="form-custom-footer">
  <button class="submit-button" mat-button (click)="submitDialog()" [disabled]="formGroup.invalid">Сохранить</button>
  <button class="cancel-button" mat-button (click)="closeDialog()">Отменить</button>
</mat-dialog-actions>
