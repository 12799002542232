// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { IEnvironment } from '@core/interfaces/environment.interface';

export const environment: IEnvironment = {
  production: false,
  test: true,
  apiUrl: 'https://d5dcisg3i9afo2k55397.apigw.yandexcloud.net',
  newApiUrl: process.env['API_URL'] ?? '',
  dadataToken: process.env['DADATA_TOKEN'] ?? '',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
