import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { SharedService } from '@shared/services/shared.service';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  @HostListener('document:keyup', ['$event'])
  @HostListener('document:click', ['$event'])
  @HostListener('document:wheel', ['$event'])
  resetTimer() {
    this.sharedService.notifyUserAction();
  }

  constructor(private sharedService: SharedService, @Inject(DOCUMENT) private document: Document) {}

  ngOnInit() {
    this.document.body.removeAttribute('data-tui-theme');
  }
}
