import { Injectable } from '@angular/core';
import { CascoSovkomProduct } from '@dealer/pages/calculation-loan-page/models/interfaces/casco-sovcom-product.interface';
import { OsagoSovkomProduct } from '@dealer/pages/calculation-loan-page/models/interfaces/osago-sovcom-product.interface';
import { StepsInterface } from '@dealer/pages/main-form-content/models/interfaces/steps.interface';
import { ClientStateData } from '../models/interfaces';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class PersistenceService {
  /** Поток данные по клиенту (фио + номер), с активной анкетой */
  public currentClientTitle$ = new BehaviorSubject<string>('');

  /** рассылаем (фио + номер) данные по клиенту, с активной анкетой */
  public setCurrentClientTitle(currentClientTitle: string) {
    this.currentClientTitle$.next(currentClientTitle);
  }

  channel = new BroadcastChannel('events-channel');
  loanApproved$ = new BehaviorSubject<boolean>(false);

  set(key: string, data: string): void {
    try {
      localStorage.setItem(key, JSON.stringify(data));
    } catch (e) {
      console.error('Error saving to localStorage', e);
    }
  }

  get(key: string): string | null {
    const storageData = localStorage.getItem(key);
    try {
      return JSON.parse(storageData as string);
    } catch (e) {
      console.error('Error getting data from LocalStorage', e);
      return null;
    }
  }

  // toDo - change any type to strict object
  public postEventToChannel(message: any): void {
    this.channel.postMessage(message);
  }

  public getToken(client?: boolean): string {
    if (!client) return JSON.parse(localStorage.getItem('accessToken')!);
    return JSON.parse(localStorage.getItem('clientAccessToken')!);
  }

  public getClientId(): string {
    return JSON.parse(localStorage.getItem('client_id')!);
  }

  public setClientDataState(data: ClientStateData, clientSelf?: boolean): void {
    // clientSelf means that method was called by client directly from the client cabinet, not by the dealer
    const client_id = this.getClientId();
    if (clientSelf) {
      const clientData: ClientStateData = JSON.parse(localStorage.getItem('clientData') as string);
      if (clientData?.client_id !== client_id || !clientData.client_id) {
        localStorage.setItem('clientData', JSON.stringify({ client_id: client_id, ...data }));
      } else {
        localStorage.setItem('clientData', JSON.stringify({ ...clientData, ...data }));
      }
    } else {
      const clientsData: ClientStateData[] = JSON.parse(
        localStorage.getItem('clientsData') as string,
      );

      if (!clientsData || clientsData.length === 0) {
        localStorage.setItem('clientsData', JSON.stringify([{ client_id: client_id }]));
        this.setClientDataState(data);
      } else {
        let clientIndex: number;
        const clientInsuranceData = clientsData.find((client: ClientStateData, i) => {
          if (client.client_id === client_id) {
            clientIndex = i;
            return client;
          }

          return null;
        });

        if (!clientInsuranceData) {
          clientsData.push({ client_id: client_id });
          localStorage.setItem('clientsData', JSON.stringify(clientsData));
          this.setClientDataState(data);
        } else {
          Object.entries(data).forEach(
            ([key, value]) => ((clientsData as Array<any>)[clientIndex][key] = value),
          );
          localStorage.setItem('clientsData', JSON.stringify(clientsData));
        }
      }
    }
  }

  public getClientDataState(clientSelf?: boolean): ClientStateData | null | void {
    const client_id = this.getClientId();

    if (clientSelf) {
      return JSON.parse(localStorage.getItem('clientData') as string);
    } else {
      const clientsData: ClientStateData[] = JSON.parse(
        localStorage.getItem('clientsData') as string,
      );

      if (!clientsData || clientsData.length === 0) {
        localStorage.setItem('clientsData', JSON.stringify([{ client_id: client_id }]));
        this.setClientDataState({});
        return this.getClientDataState();
      }

      return clientsData.find((client: ClientStateData) =>
        client.client_id === this.getClientId() ? client : null,
      );
    }
  }

  public setSelectedOsagoList(list: OsagoSovkomProduct[]): void {
    if (!list) return;
    localStorage.setItem('selected_osago', JSON.stringify(list));
  }

  public getSelectedOsagoList(): OsagoSovkomProduct[] | null {
    const loanData = localStorage.getItem('selected_osago');
    if (!loanData) return null;
    return JSON.parse(loanData);
  }

  public setSelectedCascoList(list: CascoSovkomProduct[]): void {
    if (!list) return;
    localStorage.setItem('selected_casco', JSON.stringify(list));
  }

  public getSelectedCascoList(): CascoSovkomProduct[] | null {
    const loanData = localStorage.getItem('selected_casco');
    if (!loanData) return null;
    return JSON.parse(loanData);
  }

  public setSideBarListLoanCalculation(list: StepsInterface[]): void {
    if (!list) return;
    localStorage.setItem('side-bar-list-loan-calculation', JSON.stringify(list));
  }

  public getSideBarListLoanCalculation(): StepsInterface[] {
    const sideBarListLoanCalculation = localStorage.getItem('side-bar-list-loan-calculation');
    return JSON.parse(sideBarListLoanCalculation!);
  }

  public setSideBarListProcessing(list: StepsInterface[]): void {
    if (!list) return;
    localStorage.setItem('side-bar-list-processing', JSON.stringify(list));
  }

  public getSideBarListProcessing(): StepsInterface[] {
    const sideBarListProcessing = localStorage.getItem('side-bar-list-processing');
    return JSON.parse(sideBarListProcessing!);
  }

  public clearInsuranceParams(): void {
    localStorage.removeItem('selected_osago');
    localStorage.removeItem('selected_casco');
  }

  public clearClientData(): void {
    localStorage.removeItem('clientAccessToken');
    localStorage.removeItem('client_id');
    localStorage.removeItem('clientData');
  }

  public clearField(field: string): void {
    localStorage.removeItem(field);
  }

  public setLoanId(value: string): void {
    localStorage.setItem('loan_id', JSON.stringify(value));
    this._loanIdSubject.next(value);
  }

  public getLoanIdSubject(): Observable<string> {
    return this._loanIdSubject.asObservable();
  }

  public changeLoanStatus(status: boolean): void {
    this.loanApproved$.next(status);
  }

  private _loanIdSubject = new Subject<string>();
}
