import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'separatorNumber',
})
export class SeparatorNumberPipe implements PipeTransform {
  transform(value: number): string {
    if (!value) return '';

    const numberStr: string = value.toString();
    const digits: string[] = numberStr.split('').reverse();
    const result: string[] = [];

    for (let i = 0; i < digits.length; i++) {
      result.push(digits[i]);

      if ((i + 1) % 3 === 0 && i !== digits.length - 1 && digits[i] !== '.') {
        result.push(' ');
      }
    }

    return result.reverse().join('');
  }
}
