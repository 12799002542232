import { Injectable } from '@angular/core';
import { CanLoad, Router } from '@angular/router';

import { Observable } from 'rxjs';

import { PersistenceService } from '../../../shared/services/persistence.service';
import { routingPathEnum } from '../../../shared/models';
import { UserSettingsService } from '@shared/services/user-settings.service';
/** проверка для некоторых разделов где необходимо иметь сlientId */
@Injectable()
export class DealerClientAuthGuard implements CanLoad {
  constructor(
    private router: Router,
    private persistenceService: PersistenceService,
  ) {}
  public canLoad(): Observable<boolean> | boolean {
    if (this.persistenceService.getClientId()) {
      return true;
    }
    return false;
  }
}
