import { Pipe, PipeTransform } from '@angular/core';
import { TitleCasePipe } from '@angular/common';

@Pipe({
  name: 'trimAndTitleCasePipe',
})
export class TrimAndTitleCasePipe implements PipeTransform {
  transform(value: string, titleCasePipe: boolean, trimEnd: boolean): string {
    let transformedValue = value;

    if (titleCasePipe) {
      const titleCasePipeInstance = new TitleCasePipe();
      transformedValue = titleCasePipeInstance.transform(transformedValue);
    }

    if (trimEnd) {
      transformedValue = transformedValue?.trimEnd();
    }

    return transformedValue;
  }
}
